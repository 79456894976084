// # lib
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ListItemIcon, Menu, MenuItem, MenuList, Popover, Select } from "@mui/material";
import wavesurfer from "wavesurfer.js";
// # css
import "./CallRecordingStyles.css";
import "../voice-broadcast/VoiceBroadcast.css";
import colors from "../design/colors";
import commonStyles from "../design/commonStyles";
// # components
import GModal from "../design/components/GModal";
import Button from "../design/Button";
import AnimationPage from "../shared/animation-page/AnimationPage";
import downloadIcon from "../../assets/gusers-and-teams-download-icon.svg";
// # utils
import { JService } from "../api-service/ApiService";
import {
  capitalizeString,
  formatDate,
  getHMSfromMilliSecs,
  getHMSfromMins,
  getOrgURLParam,
} from "../../utility-functions";
// # assets
import { callRecordingDownloadIcon, callRecordingGHeaderCopyIcon, callRecordingPlayIcon, callRecordingSettingsIcon, callRecordingTickIcon, topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import playIcon from "../../assets/playIcon.svg";
import pauseIcon from "../../assets/pauseIcon.svg";
import fileIcon from "../../assets/fileIcon.svg";
import threeDotsIcon from "../../assets/threeDotsIcon.svg";
import { useSelector } from 'react-redux';
import styles from '../design/styles';
import { enqueueSnackbar } from 'notistack';

const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]

const API_BASE_URL = process.env.REACT_APP_BETA

export default function CallRecording(props) {
  const reduxAccount = useSelector(state => state?.account?.value)

  const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1

  const [section1Type, setSection1Type] = useState("details");
  const [section2Type, setSection2Type] = useState("full");
  const [showLoader, setShowLoader] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [orgData, setOrgData] = useState({});

  const [selectedCallStage, setSelectedCallStage] = useState("");
  const [selectedCallOutcome, setSelectedCallOutcome] = useState("");
  const [callNotes, setCallNotes] = useState("");
  let wref = useRef(null);
  const audioRef = useRef();
  const ws = useRef();
  //const { id: callId } = useParams();
  const callId = props.callId

  const [isPlaying, setIsPlaying] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [editCandidateName, setEditCandidateName] = useState("");

  const [playbackSpeed, setPlaybackSpeed] = useState(1.0)
  const [isPlaybackMenuVisible, setIsPlaybackMenuVisible] = useState(false)
  const plabackMenuAnchorRef = useRef()
  const downloadRef = useRef()


  useEffect(() => {
    fetchRecordingData();
    fetchOrgData();
  }, []);

  useEffect(() => {
    if (audioRef.current && !ws?.current && recordingData?.recording_url) {
      ws.current = wavesurfer.create({
        container: audioRef.current,
        mediaType: "audio",
        backend: "MediaElement",
        xhr: {
          cache: "default",
          mode: "no-cors",
          method: "GET",
          credentials: "include",
          headers: [
            { key: "cache-control", value: "no-cache" },
            { key: "pragma", value: "no-cache" },
          ],
        },
        barWidth: 4,
        barRadius: 3,
        barGap: 0,
        cursorWidth: 1,
        barMinHeight: 12,
        height: 80,
        barHeight: 90,
        waveColor: colors.ic_green_300,
        progressColor: colors.nc_gray_800,
        responsive: true,
      });
      console.log("created", recordingData?.recording_url, ws);
      ws.current.load(recordingData?.recording_url);
      ws.current.drawBuffer();
      // setup onReady handler for getting peaks
      ws.current.on("ready", function () {
        // get peaks
        const peaks = ws.current.backend.getPeaks(300, 0, 300);
        const duration = ws.current.backend.getDuration();
        // console.log({ peaks: JSON.stringify(peaks), duration, bpm: bpm.current });
        // save peaks to DB
      });
    }
  }, [recordingData]);

  const fetchRecordingData = () => {
    setShowLoader(true);
    // axios
    //   .get(
    //     "https://sandbox.frejun.com/api/v1/scheduling/retrieve-call-record/?call_id=1962",
    //     {
    //       headers: {
    //         Authorization: "Token add3d161287b3e98a14fee5434d250765ef09c08",
    //       },
    //     }
    //   )
    JService.get(API_BASE_URL + `/api/v1/core/retrieve-call-record/${getOrgURLParam()}?call_id=${callId}`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Error occurred while fetching call logs")
		
		setRecordingData(res?.data);
		setSelectedCallStage(res?.data?.call_reason || "");
		setSelectedCallOutcome(res?.data?.call_outcome || "");
		setCallNotes(res?.data?.notes || "");
		setEditCandidateName(res?.data?.candidate_details?.name || "");
        setShowLoader(false);
      })
      .catch((err) => {
		enqueueSnackbar(err?.message, { variant: "error"})
        setShowLoader(false);
      });
  };

  const fetchOrgData = () => {
    JService.get(API_BASE_URL + "/api/v1/auth/retrieve-organization/")
      .then((res) => {
        if(res?.success) {
          setOrgData(res?.data);
          console.log("res orgs", res.data);
        } else throw new Error(res?.message || "Error occurred while fetching organization details")
        
        setShowLoader(false);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };

  const getSharableLink = () => {
    navigator.clipboard.writeText(recordingData.sharable_url);
	enqueueSnackbar("Recording link copied to clipboard", { variant: "success"})
  };

  const updateCallRecord = (payload) => {
    
    JService.patch("/api/v1/core/update-call-record/", payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Error occurred while updating call record")

		enqueueSnackbar(res?.message || "Updated call record", { variant: "success"})
		setRecordingData(res?.data);
		if (payload?.notes) setCallNotes(payload.notes);
		if (payload?.name) setShowEditContactModal(false);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };

  const breadcrumbs = [
    <Link
      style={{ color: colors.nc_gray_900, userSelect: "none" }}
      to="/call-logs/"
    >
      Call logs
    </Link>,
    <Link
      style={{ color: colors.ic_green_400, userSelect: "none" }}
      to={"/call-logs/"}
    >
      Call with{" "}
      {recordingData?.candidate_details?.name ||
        recordingData?.candidate_details?.phone_number}
    </Link>,
  ];

  const RecordingDetails = () => {
    const { hrs, mins, secs } = getHMSfromMins(recordingData.duration);
    return (
      <form
        className="new-contact-list-form  recording-details"
        style={{ marginTop: "40px" }}
      >
        <label className="t7 medium-font nc-gray-900">
          Date
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={formatDate(recordingData.start)}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call status
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={
              // capitalizeString(recordingData.call_type) +
              // " " +
              capitalizeString(recordingData.call_status)
            }
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Duration
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={`${hrs ? hrs + "h" : ""} ${mins}m ${secs}s`}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Contacted by
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={recordingData.creator}
            disabled
          />
        </label>
        <label className="t7 medium-font nc-gray-900">
          Virtual number
          <input
            className="t6 regular-font nc-gray-900"
            type="text"
            value={
              recordingData?.virtual_number?.country_code +
              " " +
              recordingData?.virtual_number?.number +
              " (" +
              recordingData?.virtual_number?.name +
              ")"
            }
            disabled
          />
        </label>
      </form>
    );
  };

  const RecordingNotes = () => {
    const [selectedCallStage, setSelectedCallStage] = useState(recordingData.call_reason);
    const [selectedCallOutcome, setSelectedCallOutcome] = useState(recordingData.call_outcome);
    const [localCallNotes, setLocalCallNotes] = useState("");
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setLocalCallNotes(callNotes);
    }, [callNotes]);

    useEffect(() => {
        const hasStageChanged = selectedCallStage !== recordingData.call_reason;
        const hasOutcomeChanged = selectedCallOutcome !== recordingData.call_outcome;
        const hasNotesChanged = localCallNotes !== callNotes;
        setHasChanges(hasStageChanged || hasOutcomeChanged || hasNotesChanged);
    }, [selectedCallStage, selectedCallOutcome, localCallNotes, callNotes, recordingData]);
  
    return (
      <form
        className="new-contact-list-form recording-details"
        style={{ marginTop: "40px" }}
      >
        <label className="t7 medium-font nc-gray-900">
          Call stage
          <Select
            value={selectedCallStage}
            onChange={(e) => {
              setSelectedCallStage(e.target.value);
            }}
            sx={{ ...commonStyles.select, marginTop: "4px", maxWidth: "320px" }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(p) => p}
          >
            {!!orgData?.call_reasons &&
              orgData.call_reasons.map((reason, index) => (
                <MenuItem
                  key={index}
                  sx={commonStyles.menu_item}
                  value={reason}
                >
                  {reason}
                  {selectedCallStage === reason &&
                    topNavAccountSelectedAccountTickIcon()}
                </MenuItem>
              ))}
          </Select>
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call outcome
          <Select
            value={selectedCallOutcome}
            onChange={(e) => {
              setSelectedCallOutcome(e.target.value);
            }}
            sx={{ ...commonStyles.select, marginTop: "4px", maxWidth: "320px" }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(p) => p}
          >
            {!!orgData?.call_outcomes &&
              orgData.call_outcomes.map((outcome, index) => (
                <MenuItem
                  key={index}
                  sx={commonStyles.menu_item}
                  value={outcome}
                >
                  {outcome}
                  {selectedCallOutcome === outcome &&
                    topNavAccountSelectedAccountTickIcon()}
                </MenuItem>
              ))}
          </Select>
        </label>
        <label className="t7 medium-font nc-gray-900">
          Call notes
          <textarea
            className="t6 regular-font nc-gray-900 call-notes-textarea"
            type="text"
            value={localCallNotes}
            onChange={(e) => {
              setLocalCallNotes(e.target.value);
            }}
          />
        </label>
        <Button
          variant="filled"
          buttonText="Save"
          icon={{}}
          disabled={!hasChanges}
          isLoading={false}
          hierarchy="green"
          onClick={() =>
            updateCallRecord({
              call_id: recordingData.id,
              call_reason: selectedCallStage,
              call_outcome: selectedCallOutcome,
              notes: localCallNotes,
            })
          }
        />
      </form>
    );
  };
  

  const RecordingTranscripts = () => {
    const utterances =
      section2Type === "full"
        ? recordingData?.insights?.transcript
        : section2Type === "questions"
        ? recordingData?.insights?.questions
        : [];

    return (
      <div
        style={{
          marginTop: "40px",
          height: "465px",
          overflow: "scroll",
          border: "1px solid #e1e6e2",
          borderRadius: "6px",
        }}
        className="scrollbar custom-scrollbar"
      >
        {utterances.map((ut, index) => (
          <UtteranceBlock key={index} data={ut} />
        ))}
      </div>
    );
  };

  const UtteranceBlock = ({ data }) => {
    const { hrs, mins, secs } = getHMSfromMilliSecs(data.start);

    const [mouseOver, setMouseOver] = useState(false);

    return (
      <div
        className="utterance-block"
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <p style={{ width: "63px" }} className="t7 regular-font nc-gray-900">
          {hrs !== 0 && ("0" + hrs).slice(-2) + ":"}
          {("0" + mins).slice(-2) + ":" + ("0" + secs).slice(-2)}
        </p>
        <div style={{ flex: 1 }}>
          <p className="t6 medium-font nc-gray-900" style={{ margin: 0 }}>
            {data.speaker}
          </p>
          <p className="t6 regular-font nc-gray-900" style={{ margin: 0 }}>
            {data?.text ? data.text : data?.question}
          </p>
        </div>
        <div
          style={{
            visibility: mouseOver ? "visible" : "hidden",
          }}
          className="transcript-view-btn"
        >
          <p
            onClick={() => handleSeekToTime(data?.start)}
            className="b2 medium-font"
          >
            View
          </p>
          {/* <p onClick={() => handleSeekToTime(data?.start)} buttonText={'View'} hierarchy='white' variant='contained' isLoading={false} disabled={false} /> */}
        </div>
      </div>
    );
  };

  function handleSeekToTime(seekToTime) {
    // seek to the time of the utterance
    if (!seekToTime) {
      console.warn("Invalid time");
      return;
    }
    ws.current?.setCurrentTime(seekToTime / 1000);
  }

  function handlePlaybackSpeedChange(speed) {
	console.log(speed)
	setPlaybackSpeed(speed)
	ws.current.setPlaybackRate(speed)
	handlePlaybackPopoverClose()
  }

  function handleOpenSettingsMenu() {
	setIsPlaybackMenuVisible(prev => !prev)
  }

  function handlePlaybackPopoverClose() {
	setIsPlaybackMenuVisible(false)
  }

  function handleDownload() {
	console.log("download container clicked")
	downloadRef.current.dispatchEvent(
		new MouseEvent('click', { 
		bubbles: true, 
		cancelable: true, 
		view: window 
		})
	)

	// fetch fails due to cors
	// const data = await fetch(recordingData?.recording_url);
  	// const blob = await data.blob();
	// const blobUrl = URL.createObjectURL(blob);

	// const link = document.createElement("a");
	// link.href = blobUrl;
	// link.download = "call-recording-"+Date.now();
	// document.body.appendChild(link);

	// //link.click() does not work 
	// link.dispatchEvent(
	// 	new MouseEvent('click', { 
	// 	bubbles: true, 
	// 	cancelable: true, 
	// 	view: window 
	// 	})
	// )
	// document.body.removeChild(link)
  }

  function handleAnchorClick(e) {
	console.log("downloading recording")
	e.stopPropagation()
	return true
  }
  const handleDownloadTranscript = () => {
    const transcriptData = recordingData?.insights?.transcript;
    const questionsData = recordingData?.insights?.questions;
  
    if (transcriptData && transcriptData.length > 0) {
      let filteredTranscript;
  
      if (section2Type === "questions") {
        filteredTranscript = questionsData.map(entry => {
          return `${entry.speaker}: ${entry.question}`;
        });
      } else {
        filteredTranscript = transcriptData.map(entry => {
          return `${entry.speaker}: ${entry.text}`;
        });
      }
  
      const textToDownload = filteredTranscript.join('\n');
      const blob = new Blob([textToDownload], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "transcript.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      console.error("No transcript data available for download");
    }
  };

  
  return (
    <div className="modal-screen-container screen-container call-recording-container">

      {/* threedots disabled headers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
		<div 
		  style={{
			paddingTop: '24px',
			display: 'flex', flexDirection: "column", alignItems: "flex-start", 
		  }}
		>
			<p style={{marginTop: "0px"}} className="t4 medium-font nc-gray-900 call-recording-title"> {recordingData?.candidate_details?.name || "-"} </p>
			{(maskNumbers && recordingData?.candidate_details?.name?.trim()?.length) ? null : <p style={{marginTop: "0px"}} className="t5 normal-font nc-gray-900 call-recording-title"> {recordingData?.candidate_details?.phone_number || "-"} </p>}
		</div>

		  <div
            className="three-dots-btn"
			
            onClick={(e) => {
				getSharableLink();
            //   setAnchorEl(e.currentTarget);
			//   e.stopPropagation();
            }}
          >
			<Button
				buttonText="Copy link"
				hierarchy="white"
				variant="outlined"
				icon={{startIcon: callRecordingGHeaderCopyIcon(), endIcon: null}}
				onClick={(e) => {
				console.log("button clicked")
				}}
				styleOverride={{
					width: "150px",
          marginTop:'10px'
				}}
			/>
        </div>
      </div>

      <div
        style={{
          borderBottom: "1px solid #e1e6e2",
          // margin: "32px -32px 33px -32px",
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="row-options-foreground"
          style={{ position: "relative", width: "150px" }}
        >
          <button
            className="t6 nc-gray-900"
            style={{ textAlign: "left" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowEditContactModal(true);
            }}
          >
            <p className="t6 regular-font nc-gray-900">Edit contact</p>
          </button>
          <button
            className="t6 nc-gray-900"
            style={{ textAlign: "left" }}
            onClick={(e) => {
              getSharableLink();
              e.stopPropagation();
            }}
          >
            <p className="t6 regular-font nc-gray-900">Copy link</p>
          </button>
        </div>
      </Popover>

      {!!showLoader && <AnimationPage />}

      {!!Object.keys(recordingData).length != 0 && (
        <div className="insights-container">
          <div style={{ display: "flex", paddingBottom: "0px", }}>
            {/* SECTION 1  */}
            <div className="section_1">
              {/* if insights is enabled */}
              {!!recordingData?.insights ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="t5 medium-font nc-gray-900">Call</p>
                    <Select
                      value={section1Type}
                      onChange={(e) => {
                        setSection1Type(e.target.value);
                      }}
                      sx={{ ...commonStyles.select, width: "160px" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      renderValue={(p) => capitalizeString(p)}
                    >
                      <MenuItem sx={commonStyles.menu_item} value="details">
                        Details
                        {section1Type === "details" &&
                          topNavAccountSelectedAccountTickIcon()}
                      </MenuItem>
                      <MenuItem sx={commonStyles.menu_item} value="notes">
                        Notes
                        {section1Type === "notes" &&
                          topNavAccountSelectedAccountTickIcon()}
                      </MenuItem>
                    </Select>
                  </div>
                  {section1Type === "details" && <RecordingDetails />}

                  {section1Type === "notes" && <RecordingNotes />}
                </>
              ) : (
                // If ai insights not enabled
                <>
                  <p className="t5 medium-font nc-gray-900">Details</p>
                  <RecordingDetails />
                </>
              )}
            </div>
            {/* SECTION 1  */}
            <div className="section_2">
              {!!recordingData?.insights?(
                // if insights is enabled
                <>
                  <div
                     style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p className="t5 medium-font nc-gray-900">Transcripts</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      value={section2Type}
                      onChange={(e) => setSection2Type(e.target.value)}
                      sx={{ ...commonStyles.select, width: "240px" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      renderValue={(p) => (p === "full" ? "Full transcript" : "Questions")}
                    >
                      <MenuItem sx={commonStyles.menu_item} value="full">
                        Full transcript
                        {section2Type === "full" && topNavAccountSelectedAccountTickIcon()}
                      </MenuItem>
                      <MenuItem sx={commonStyles.menu_item} value="questions">
                        Questions
                        {section2Type === "questions" && topNavAccountSelectedAccountTickIcon()}
                      </MenuItem>
                    </Select>
                    <div
                      onClick={handleDownloadTranscript}
                      style={{
                        border: "1px solid lightgray",
                        borderRadius: "3px",
                        padding: "8px",
                        marginLeft: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={downloadIcon} alt="Download icon" />
                    </div>
                  </div>
                  </div>
                  {recordingData?.insights?.transcription_status ===
                  "completed" ? (
                    <RecordingTranscripts />
                  ) : (
                    <div
                      style={{
                        height: "308px",
                        border: "1px solid #E1E6E2",
                        borderRadius: "10px",
                        marginTop: "21px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "88px",
                          height: "88px",
                          borderRadius: "100px",
                          backgroundColor: "#F7FAF8",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={fileIcon} alt="breadcrumbs separtor icon" />
                      </div>
                      <p
                        style={{ marginTop: "15px" }}
                        className="t5 medium-font nc-gray-900"
                      >
                        Processing
                      </p>
                      <p
                        style={{ marginTop: "16px" }}
                        className="t6 regular-font nc-gray-900"
                      >
                        Your transcript is being generated.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                // if insights not enabled
                <>
                  <p className="t5 medium-font nc-gray-900">Notes</p>
                  <RecordingNotes />
                </>
              )}
            </div>
          </div>
		  
		  {!!recordingData?.recording_url && (recordingData?.duration !== 0) && (
            <div className="recording">
              <div
                style={{
                  display: "flex",
				  alignItems :'center',
                  flexDirection: "row",
                  alignItems: "center",
				  gap: "10px",
				  paddingInline: "12px",
                }}
              >
                <div
                  style={{ cursor: "pointer", borderWidth: ws.current?.isPlaying()? "2px" : "0px" }}
                  onClick={(e) => {
                    console.log(ws.current);
                    if (isPlaying) {
                      ws.current.pause();
                      setIsPlaying(false);
                    } else {
                      ws.current.play();
                      setIsPlaying(true);
                    }
                  }}
                  className="pause-play-btn"
                >
                  {ws.current?.isPlaying() ? <img src={pauseIcon}/> : callRecordingPlayIcon()}
                </div>

                <div
				  className='wave-container'
                  ref={audioRef}
                  style={{
                    position: "relative",
                    minWidth: "200px",
                    width: "100%",
                    zIndex: 2,
                    flex: 1,
					overflowX: "scroll"
                  }}
                />
				
				{/* speed */}
				<div ref={plabackMenuAnchorRef} className="settings-icon-container" onClick={handleOpenSettingsMenu} style={{
					background: isPlaybackMenuVisible ? colors.nc_gray_100 : colors.ic_white,
				}}>
					{callRecordingSettingsIcon()}
				</div>

				{/* download */}
				{[1, 4].includes(reduxAccount?.data?.profile?.role) ? <div onClick={handleDownload} className='download-icon-container'>
					<a ref={downloadRef} onClick={handleAnchorClick} onMouseDown={handleAnchorClick} download target="_blank" href={recordingData?.recording_url}> {callRecordingDownloadIcon()} </a>
				  </div>
					: null}
              </div>
            </div>
          )}
        </div>
      )}

	  {/* popover for playbackSpeed using Menu */}
	  <Menu
		anchorOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		autoFocus={false}
		open={isPlaybackMenuVisible}
		anchorEl={plabackMenuAnchorRef?.current || null}
		onClose={handlePlaybackPopoverClose}
		sx={{
			"& .MuiPaper-root": {
				width: "160px",
				border: `1px solid ${colors.nc_gray_300}`,
				borderRadius: "6px",
			}
		}}
	  >
		<p style={{color: colors.nc_gray_900, ...styles.bold_font, textAlign: "left", paddingLeft: "16px"}}>Speed</p>
		{PLAYBACK_SPEEDS.map(speed => (
			<MenuItem 
				key={speed} 
				sx={{
					paddingRight: "0px",
					display: "flex",
					flexDirection: "row", 
					alignItems: "center", 
					justifyContent: "space-between", 
					backgroundColor: speed === playbackSpeed ? colors.ic_green_100 : colors.ic_white,
					"&:hover": {
						background: colors.nc_gray_100,
					}
				}}
				onClick={() => handlePlaybackSpeedChange(speed)}
				value={speed} 
			>
				{speed === 1 ? "Normal" : speed + "x"}
				<ListItemIcon style={{marginLeft: "12px", paddingRight: "12px"}}>
					{speed === playbackSpeed && callRecordingTickIcon()}
				</ListItemIcon>
			</MenuItem>
		))}
	  </Menu>

      <GModal
        modalStyle={{ paddingTop: "0" }}
        disableCloseButton={false}
        closeModal={() => setShowEditContactModal(false)}
        body={
          <>
            <form
              className="new-contact-list-form  recording-details"
              style={{ marginTop: "40px" }}
            >
              <label className="t7 medium-font nc-gray-900">
                Name
                <input
                  className="t6 regular-font nc-gray-900"
                  type="text"
                  value={editCandidateName}
                  onChange={(e) => {
                    setEditCandidateName(e.target.value);
                  }}
                />
              </label>
              <label className="t7 medium-font nc-gray-900">
                Phone number
                <input
                  className="t6 regular-font nc-gray-900"
                  type="text"
                  value={recordingData?.candidate_details?.phone_number}
                  disabled
                />
              </label>
            </form>
          </>
        }
        heading={
          <span className="t6 nc-gray-900 medium-font">Edit contact</span>
        }
        visible={showEditContactModal}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={() => setShowEditContactModal(false)}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={() =>
              updateCallRecord({
                call_id: recordingData.id,
                name: editCandidateName,
              })
            }
          />,
        ]}
      />
    </div>
  );
}
